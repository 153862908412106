import useAuth from "@/modules/auth/composables/useAuth";
import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {TaxForm} from "@/modules/accounting_configuration/interfaces";
import store from "@/store";

const {companyId} = useAuth()
const defaultValues = (): TaxForm => {
    return {
        id: uuid(),
        name: "",
        description: "",
        rate: 0.00,
        status: "active",
        companyId: companyId.value
    }
}
const taxForm: Ref<TaxForm> = ref(defaultValues())

const useTax = () => {
    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ]
    };

    const clearForm = () => taxForm.value = defaultValues()

    const createTax = async (taxForm: TaxForm) => {
        return await store.dispatch('accounting_configuration/createTax', taxForm)
    }

    const updateTax = async (taxForm: TaxForm) => {
        return await store.dispatch('accounting_configuration/updateTax', taxForm)
    }

    const getTax = async (id: string): Promise<TaxForm> => {
        const {data} = await store.dispatch('accounting_configuration/getTax', id)

        return data;
    }

    return {
        taxForm,
        catalogs,
        clearForm,
        createTax,
        updateTax,
        getTax
    }
}

export default useTax;
