
import {onMounted, ref, watch} from "vue";
import useTax from "@/modules/accounting_configuration/composables/useTax";
import usePanel from "@/composables/usePanel";

const {taxForm} = useTax();

export default {
    props: ['catalogs'],
    setup() {
        const {showPanel, togglePanel} = usePanel()

        const name = ref('')
        const description = ref('')
        const rate = ref(0)
        const status = ref('')

        onMounted(() => {
            name.value = taxForm.value.name
            description.value = taxForm.value.description
            rate.value = taxForm.value.rate
            status.value = taxForm.value.status
        })

        watch(name, val => taxForm.value.name = val)
        watch(description, val => taxForm.value.description = val)
        watch(rate, val => taxForm.value.rate = val)
        watch(status, val => taxForm.value.status = val)
        watch(taxForm, val => {
            name.value = val.name
            description.value = val.description
            rate.value = val.rate
            status.value = val.status
        })

        return {
            showPanel,
            togglePanel,
            name,
            description,
            rate,
            status,
            taxForm
        }
    }
}
