<template>
    <form @submit.prevent="onSubmit" autocomplete="off">
        <GeneralData :table-name="tableName" :catalogs="catalogs"></GeneralData>

        <div class="pb-4 pr-0 pr-md-3 pt-3 row">
            <div class="col-6 col-md-2 col-sm-6 offset-md-8">
                <button type="button" class="btn btn-block btn-outline-secondary mr-5 pl-3 pr-3"
                        style="margin: 10px 10px 10px 0;" @click="onCancel">Cancelar
                </button>
            </div>
            <div class="col-6 col-md-2 col-sm-6" style="margin-top: 3px;">
                <button type="submit" class="btn btn-block btn-blue-deg mb-2 mr-0 mr-md-5 mt-2"
                        style="height: 38px;" :disabled="sending">Guardar
                </button>
            </div>
        </div>
    </form>

    <MainTable :table-name="tableName"></MainTable>
</template>

<script>
import MainTable from "@/modules/accounting_configuration/components/taxes/MainTable.vue";
import GeneralData from "@/modules/accounting_configuration/components/taxes/GeneralData.vue";
import {onMounted, ref} from "vue";
import toastr from "toastr";
import useTax from "../composables/useTax";
import useAuth from "../../auth/composables/useAuth";
import useMainTable from "../../../composables/useMainTable";

const CREATE = 'CREATE'
const UPDATE = 'UPDATE'

export default {
    components: {GeneralData, MainTable},
    setup() {
        const mode = ref(CREATE)
        const sending = ref(false)
        const tableName = 'taxes'
        const {companyId} = useAuth();
        const {
            clearForm,
            taxForm,
            createTax,
            catalogs,
            updateTax,
            getTax
        } = useTax()

        onMounted(() => {
            const {onViewOptions, onChangeStatus, onStateButton} = useMainTable(tableName)

            onViewOptions('taxes/options_list')
            window.$('body').off('click', '.showRow').on('click', '.showRow', async function (e) {
                e.preventDefault();

                let modal = window.$('#optionsModal');
                modal.find('.modal-body').empty().html(`
                 <div class="M-load">
                    <i class="fa fa-spinner" aria-hidden="true"></i> Cargando...
                </div>
                `);

                const id = window.$(this).attr("data-id");
                taxForm.value = await getTax(id)
                mode.value = UPDATE
                modal.modal('hide');
            })
            onChangeStatus('taxes/status_list')
            onStateButton('taxes/status_list')
        })

        const onCancel = () => {
            mode.value = 'CREATE'
            clearForm()

            const list = window.$('body').find("#list" + tableName);

            list.setGridParam({postData: null});
            list.setGridParam({
                postData: {
                    filters: [
                        {field: 'companyId', value: companyId.value}
                    ]
                }
            });
            list.trigger('reloadGrid');
        }

        return {
            sending,
            tableName,
            catalogs,
            onSubmit: async () => {
                try {
                    sending.value = true

                    const {ok, message} = mode.value === 'CREATE'
                        ? await createTax(taxForm.value)
                        : await updateTax(taxForm.value)

                    if (ok) {
                        toastr.success('Su solicitud se ha procesado correctamente.')
                        await onCancel()
                    } else {
                        await toastr.error(message)
                    }
                } finally {
                    sending.value = false
                }
            },
            onCancel
        }
    }
}
</script>
